// var _$53 = _$53 || [];//注：预定义对象，变量名不可更改，使用前请检查是否和自身网站变量名冲突

// _$53.push(["_setGuestInfo", {
// 	id: '1000051',
// 	name: 'dai222',
// 	email: '11@163.com',
// 	qq: '111111',
// 	phone: '15248963256',
// 	company: '111',
// 	address: '111',
// 	notes: '111',
// 	crm_fields: '{"field_14021":"545641415"}', //自定义字段
// 	custom_info: '1112'
// }]);



//1. 以下方法，网站pc端和移动端通用
//2. 对应的api文档 https://www.eiisys.com/home/apiDetails?id=00300000009
//3. 在网站上添加这段a标签，点击后会弹出弹窗  <a href="javascript:" onclick="open53()">点击此处进入对话</a>
//4. 下面方法可以直接在网站上使用
export function open53 () {
	var _$53 = _$53 || [];//注：预定义对象，变量名不可更改，使用前请检查是否和自身网站变量名冲突
	let user = JSON.parse(localStorage.getItem('user'))
	let e = localStorage.getItem('listid')
	if(user){
		_$53.push(["_setGuestInfo", {
		  id: user.idcode,
		  name: user.idcode,
		  email: '',
		  qq: '',
		  phone: user.mobile,
		  company: '',
		  address: '',
		  notes: user.nickname,
		  crm_fields: { 'field_14021': e }, //自定义字段
		  custom_info: '',
		}]);
	}


	var _53api = $53.createApi();    //$53这个对象是从53挂码获取的	
	_53api.push('cmd', 'kfclient');
	_53api.push('type', 'new');    //popup是弹窗，new是新窗口。浮动窗口就是页面右下角弹出的小窗口，新窗口是一个相对大的浮层窗口，可以自己测试看下
	//_53api.push('worker_id', '1006');    //这个参数用于指定接待客服。正常不用设置，会按照53后台设置的接待规则分配客服。worker_id是客服的工号，用于指定接待客服，如果不设置，会按照后台设置的接待规则分配客服。账号对应的工号可以到工号管理中查看。工号管理登录地址 http://open.53kf.com/?go_url=https://saasjh.53kf.com/2mastersaas
	_53api.query();


}

