import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    type:false,
    list:{field_14021:''},
  },
  getters: {
  },
  mutations: {
    SET_COUNT(state, newCount) {
      state.type = newCount;
    },
    ORDER_ID(state, newCount){
      state.list.field_14021=newCount
    }
  },
  actions: {
  },
  modules: {
  }
})
