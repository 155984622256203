import  {request}  from "./request"
import JSEncrypt from 'jsencrypt';
const encrypt = new JSEncrypt();
encrypt.setPublicKey('MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAu4kKGFxZsxjES1zL+UzSBuv5fxjAtqPvg7dx/hrxeQR1emGYherxf/RWrW2EPHtTo0MUtaXL5YVtUcdaFuspYGXCsA97oAHNMRfKNnwJapJzLLRYRBn0a0u/y/kfWM5k7UQkeRAOtV7D7m89ADGEMUUPeSRdHFH3ITiaQE3XX9DktauvhhgtnHficS/XHEa0yrHxHrYNrJqgT3i7PJYDD50PxnHnboERviNOTZWrk0D5jIi+zmgb4HfDzSzv8X0zzWC8PUuDfVbSwDwqHLTA1wFZsEG/xtfzD0w5AIXzoyavxwzaZbJBnbZu5Gkrkfl4VZrdnGJgRh0V0U/Z5pX6BwIDAQAB');//设置公有key
//轮播图
export function CarouselApi (loginData) {
	return request({
		url: 'api/index/slide',
		method: 'get',
		params: loginData
	})
}
//文章列表
export function ArticleListApi (loginData) {
	return request({
		url: 'api/Hrarticle/articlelist',
		method: 'post',
		data: loginData
	})
}
//文章分类
export function ArticleClassApi (loginData) {
	return request({
		url: 'api/Hrarticle/articlecategorize',
		method: 'get',
		params: loginData
	})
}
//文章详情
export function ArticleDetailApi (loginData) {
	return request({
		url: 'api/Hrarticle/articledetail',
		method: 'get',
		params: loginData
	})
}

//温馨提示
export function TipsApi (loginData) {
	return request({
		url: 'api/index/tips',
		method: 'get',
		params: loginData
	})
}

//单页面列表
export function SingLepageApi (loginData) {
	return request({
		url: 'api/index/singlepage',
		method: 'get',
		params: loginData
	})
}
//单页面详细
export function SingDetailApi (loginData) {
	return request({
		url: 'api/index/singlepagedetail',
		method: 'post',
		data: loginData
	})
}

//游戏分类
export function GameApi (loginData) {
	return request({
		url: 'api/index/getclass',
		method: 'post',
		data: loginData
	})
}

//商品列表
export function ShopListApi (loginData) {
	return request({
		url: 'api/hrshop/list',
		method: 'post',
		data: loginData
	})
}

//黑名单查询
export function BlockApi (loginData) {
	return request({
		url: 'api/user/block',
		method: 'post',
		data: loginData
	})
}

//发布商品
export function AddProduct (loginData) {
	loginData.shop_account=encrypt.encrypt(loginData.shop_account); //加密
	loginData.shop_psd=encrypt.encrypt(loginData.shop_psd); //加密
	return request({
		url: 'api/goods/add',
		method: 'post',
		data: loginData
	})
}

//配置信息
export function ConfigApi (loginData) {
	return request({
		url: 'api/index/config',
		method: 'get',
		data: loginData
	})
}


//商品标签
export function ProductLabelApi (loginData) {	
	return request({
		url: 'api/index/tabsclomuncontent',
		method: 'post',
		data: loginData
	})
}

//商品详情
export function DateilApi (loginData) {
	return request({
		url: 'api/hrshop/detail',
		method: 'get',
		params: loginData
	})
}

//收藏商品
export function CollectApi (loginData) {
	return request({
		url: 'api/Hrusershop/add',
		method: 'get',
		params: loginData
	})
}
//取消收藏
export function CancelApi (loginData) {
	return request({
		url: 'api/Hrusershop/del',
		method: 'get',
		params: loginData
	})
}
//获取是否收藏商品
export function FavApi (loginData) {
	return request({
		url: 'api/Hrusershop/fav',
		method: 'get',
		params: loginData
	})
}

//砍价购买
export function DddcutApi (loginData) {
	return request({
		url: 'api/orders/addcut',
		method: 'post',
		params: loginData
	})
}

//砍价购买订单支付-保证金
export function PaycutApi (loginData) {
	return request({
		url: 'api/orders/paycut',
		method: 'post',
		params: loginData
	})
}

//砍价订单-商家还价
export function AddcutbsApi (loginData) {
	return request({
		url: 'api/orders/addcutbs',
		method: 'post',
		params: loginData
	})
}

//砍价订单-用户确认
export function AddcutoKuApi (loginData) {
	return request({
		url: 'api/orders/addcutoku',
		method: 'post',
		params: loginData
	})
}
//砍价订单-用户还价
export function addcutBuApi (loginData) {
	return request({
		url: 'api/orders/addcutbu',
		method: 'post',
		params: loginData
	})
}

//砍价订单-用户拒绝
export function addcutrefuseuApi (loginData) {
	return request({
		url: 'api/orders/addcutrefuseu',
		method: 'post',
		params: loginData
	})
}

//砍价购买订单支付-砍价金额
export function paycutendApi (loginData) {
	return request({
		url: 'api/orders/paycutend',
		method: 'post',
		params: loginData
	})
}


//获取分期期数列表
export function AgingListApi (loginData) {
	return request({
		url: 'api/Hrinstallments/hrinstallmentslist',
		method: 'get',
		params: loginData
	})
}

//获取分期金额详情
export function AgingListDetailsApi (loginData) {
	return request({
		url: 'api/Hrinstallments/hrinstallmentsdetail',
		method: 'post',
		params: loginData
	})
}
//保险分类
export function InsuranceClassApi (loginData) {
	return request({
		url: 'api/index/insurancetabsclomun',
		method: 'get',
		params: loginData
	})
}

//保险列表
export function InsuranceListApi (loginData) {
	return request({
		url: 'api/index/insurancetabsclomuncontent',
		method: 'post',
		data: loginData
	})
}

//分期购买
export function AddByApi (loginData) {
	return request({
		url: 'api/orders/addby',
		method: 'post',
		data: loginData
	})
}
//分期购买订单支付
export function PayByApi (loginData) {
	return request({
		url: 'api/orders/payby',
		method: 'post',
		data: loginData
	})
}

//直接购买
export function DddApi (loginData) {
	return request({
		url: 'api/orders/add',
		method: 'post',
		data: loginData
	})
}

//直接购买订单支付
export function PayApi (loginData) {
	return request({
		url: 'api/orders/pay',
		method: 'post',
		data: loginData
	})
}

//专业评估
export function AppraiseApi (loginData) {
	loginData.shop_account=encrypt.encrypt(loginData.shop_account); //加密
	loginData.shop_psd=encrypt.encrypt(loginData.shop_psd); //加密
	return request({
		url: 'api/orderas/add',
		method: 'post',
		data: loginData
	})
}

//分期订单剩余金额支付
export function paybyendApi (loginData) {
	return request({
		url: 'api/orders/paybyend',
		method: 'post',
		data: loginData
	})
}

//增加浏览记录
export function HrbAddApi (loginData) {
	return request({
		url: 'api/Hrbrowsingrecords/add',
		method: 'post',
		data: loginData
	})
}

//砍价购买订单支付-选择保险
export function paycuttoApi (loginData) {
	return request({
		url: 'api/orders/paycutto',
		method: 'post',
		data: loginData
	})
}

//最新成交
export function neworderApi (loginData) {
	return request({
		url: 'api/index/neworder',
		method: 'get',
		data: loginData
	})
}


//订单支付状态
export function orderspayApi (loginData) {
	return request({
		url: 'api/index/orderspay',
		method: 'post',
		data: loginData
	})
}

//充值订单状态
export function chargepayApi (loginData) {
	return request({
		url: 'api/index/chargepay',
		method: 'post',
		data: loginData
	})
}

//充值订单支付
export function moneypayApi (loginData) {
	return request({
		url: 'api/money/pay',
		method: 'post',
		data: loginData
	})
}

//关于我们
export function aboutApi (loginData) {
	return request({
		url: 'api/index/about',
		method: 'post',
		data: loginData
	})
}

//关于我们详情
export function aboutshowApi (loginData) {
	return request({
		url: 'api/index/aboutshow',
		method: 'get',
		params: loginData
	})
}
//评估订单支付
export function orderaspayApi (loginData) {
	return request({
		url: 'api/orderas/pay',
		method: 'post',
		data: loginData
	})
}


//评估订单支付状态
export function ordersaspayApi (loginData) {
	return request({
		url: 'api/index/ordersaspay',
		method: 'post',
		data: loginData
	})
}


//支付剩余全部款项 状态判断
export function ordersbypayApi (loginData) {
	return request({
		url: 'api/index/ordersbypay',
		method: 'post',
		data: loginData
	})
}

//游戏自定义标签
export function labelsApi (loginData) {
	return request({
		url: 'api/hrshop/labels',
		method: 'get',
		params: loginData
	})
}

//示例图
export function ExampleApi (loginData) {
	return request({
		url: 'api/hrshop/classimg',
		method: 'get',
		params: loginData
	})
}

//分类全部
export function SortallApi (loginData) {
	return request({
		url: 'api/index/getclasslists',
		method: 'post',
		params: loginData
	})
}
