import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import { Message } from 'element-ui';
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/roster',
    name: 'roster',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/roster.vue')
  },
  {
    path: '/list_articles',
    name: 'list_articles',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/list_articles.vue')
  },
  {
    path: '/articles_deta',
    name: 'articles_deta',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/articles_deta.vue')
  },
  {
    path: '/my_going',
    name: 'my_going',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/my_going/my_going.vue')
  },
  {
    path: '/my_going_list',
    name: 'my_going_list',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/my_going/my_going_list.vue')
  }
  ,
  {
    path: '/my_going_deta',
    name: 'my_going_deta',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/my_going/my_going_deta.vue')
  }
  ,
  {
    path: '/stages',
    name: 'stages',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/my_going/stages.vue')
  }
  ,
  {
    path: '/purchase',
    name: 'purchase',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/my_going/purchase.vue')
  }
  ,
  {
    path: '/single',
    name: 'single',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/single/single.vue')
  }
  ,
  {
    path: '/sell_list',
    name: 'sell_list',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/my_sell/sell_list.vue')
  }
  ,
  {
    path: '/select_area',
    name: 'select_area',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/my_sell/select_area.vue')
  }
  ,
  {
    path: '/complete_message',
    name: 'complete_message',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/my_sell/complete_message.vue')
  }
  ,
  {
    path: '/valuation',
    name: 'valuation',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/valuation/valuation.vue')
  }
  ,
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/login/login.vue')
  }
  ,
  {
    path: '/enroll',
    name: 'enroll',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/login/enroll.vue')
  }
  ,
  {
    path: '/remember',
    name: 'remember',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/login/remember.vue')
  }
  ,
  {
    path: '/agreement',
    name: 'agreement',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/agreement.vue')
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/single/about.vue')
  }
  ,
  {
    path: '/user',
    name: 'user',
    redirect: "/user",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/user/user.vue'),
    children: [

      {
        path: '',
        name: 'information',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/user/information.vue'),
      },
      {
        path: 'personal_data',
        name: 'personal_data',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/user/personal_data.vue'),
      }
      ,
      {
        path: 'password',
        name: 'password',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/user/password.vue'),
      }
      ,
      {
        path: 'attestation',
        name: 'attestation',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/user/attestation.vue'),
      }
      ,
      {
        path: 'account_atpn',
        name: 'account_atpn',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/user/account_atpn.vue'),
      }
      ,
      {
        path: 'realname_atpn',
        name: 'realname_atpn',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/user/realname_atpn.vue'),
      }
      ,
      {
        path: 'compensate_aptn',
        name: 'compensate_aptn',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/user/compensate_aptn.vue'),
      }
      ,
      {
        path: 'my_goods',
        name: 'my_goods',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/user/my_goods.vue'),
      }
      ,
      {
        path: 'goods_deta',
        name: 'goods_deta',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/user/goods_deta.vue'),
      }
      ,
      {
        path: 'CollectibleGoods',
        name: 'CollectibleGoods',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/user/CollectibleGoods.vue'),
      }
      ,
      {
        path: 'MyProduct',
        name: 'MyProduct',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/user/MyProduct.vue'),
      }
      ,
      {
        path: 'EstimatedOrder',
        name: 'EstimatedOrder',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/user/EstimatedOrder.vue'),
      }
      ,
      {
        path: 'BalanceRecharge',
        name: 'BalanceRecharge',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/user/BalanceRecharge.vue'),
      }
      ,
      {
        path: 'BalanceDetail',
        name: 'BalanceDetail',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/user/BalanceDetail.vue'),
      }
      ,
      {
        path: 'BrowsingHistory',
        name: 'BrowsingHistory',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/user/BrowsingHistory.vue'),
      }
      ,
      {
        path: 'ServiceMessage',
        name: 'ServiceMessage',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/user/ServiceMessage.vue'),
      }
      ,
      {
        path: 'Bargaingoods',
        name: 'Bargaingoods',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/user/Bargaingoods.vue'),
      }
      ,
      {
        path: 'commodityDetail',
        name: 'commodityDetail',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/user/commodityDetail.vue'),
      }
      ,
      {
        path: 'assessDetails',
        name: 'assessDetails',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/user/assessDetails.vue'),
      }
      ,
      {
        path: 'reviseNumber',
        name: 'reviseNumber',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/user/reviseNumber.vue'),
      }

    ]
  }



]




const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  if (to.path == '/my_going_deta') {
    localStorage.setItem('from_user_id',to.query.id)
    localStorage.setItem('from_user', to.path)
  }
  if(from.path=='/login'){
    console.log('登录页')
    localStorage.setItem('loginop53',true)
  }
  const mobileUserAgent = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
  if (mobileUserAgent.test(window.navigator.userAgent)) {
    return window.location.href = "http://9527.cn/h5/index.html";
  }

  window.scrollTo(0, 0);
  
  // 这里可以进行身份验证、权限检查等操作
  if ((to.path == '/sell_list' && !localStorage.getItem('token')) || (to.path == '/valuation' && !localStorage.getItem('token')) || (to.path == '/user' && !localStorage.getItem('token'))) {
    console.log('333333333333')
    next('/login'); // 如果用户未认证，重定向到登录页面
  } else {
    next(); // 否则，继续路由
  }

});

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router
