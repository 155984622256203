<template>
	<div id="app">
		<navtop v-if="status"></navtop>
		<router-view />
		<foot v-if="status"></foot>
		<SideBar v-if="status"></SideBar>
	</div>
</template>
<script>
import navtop from '../src/components/top.vue'
import foot from '../src/components/foot.vue'
import SideBar from '../src/components/SideBar.vue'
import { GoodsListsApi } from '@/work/user'
export default ({
	components: {
		navtop,
		foot,
		SideBar
	},
	data () {
		return {
			status: true
		}
	},
	watch: {
		//监听路由变化， 
		$route (to, from, next) {
			this.getlist()
			if (to.name == 'login' || to.name == 'enroll' || to.name == 'remember') {
				this.status = false
			} else {
				this.status = true
			}

		}
	},
	mounted () {

	},
	methods: {
		getlist () {
			let obj = {
				page: 1,
				pagesize: 3
			}
			GoodsListsApi(obj).then(res => {
				if (res.data.code == 1) {
					let listid = {}
					let list = []
					res.data.data.data.forEach((element, index) => {
						list.push(element.id)
					});
					listid = list.join(',')
					localStorage.setItem('listid', listid)
					// this.$store.commit('ORDER_ID', listid);
					// console.log(listid, '测试')
				}
			})
		}
	}


})
</script>

<style lang="scss">
* {
	margin: 0;
	padding: 0;
}
#app {
	position: relative;
	// font-family: Avenir, Helvetica, Arial, sans-serif;
	// -webkit-font-smoothing: antialiased;
	// -moz-osx-font-smoothing: grayscale;
	// text-align: center;
	// color: #2c3e50;
}
a {
	text-decoration: none;
}
ul {
	list-style: none;
}
input,
textarea {
	border: 0;
}
input:focus,
textarea:focus {
	outline: none;
	border: none;
}
:-webkit-autofill {
	-webkit-text-fill-color: #ffffff !important;
	transition: background-color 5000s ease-in-out 0s;
}

.curtain {
	width: 100%;
	min-height: 100%;
	background: #00000096;
	position: fixed;
	left: 0;
	top: 0;
	z-index: 999;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
}
input[type='number'] {
	-moz-appearance: textfield;
}
// nav {
//   padding: 30px;

//   a {
//     font-weight: bold;
//     color: #2c3e50;

//     &.router-link-exact-active {
//       color: #42b983;
//     }
//   }
// }
</style>
