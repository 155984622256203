
import axios from 'axios';
// 使用element-ui Message做消息提醒
import { Message } from 'element-ui';
import { Loading } from 'element-ui';

const options = {
  spinner: 'el-icon-loading',
  lock: true,
  text: '加载中...',
  background: 'rgba(0, 0, 0, 0.7)',
  // fullscreen:false,
  // target指定需要覆盖的 DOM 节点
  target: '#app',
}
let loadingInstance;

export function request (config) {
  const instance = axios.create({
    // baseURL: 'http://192.168.1.2:8068/',
    baseURL:'http://9527.cn/',  
    // baseURL:'http://shf68.shuzidianli.cn/',
    timeout: 20000
  });

  // 拦截请求。请求在到达服务器之前会首先调用这个函数对请求做一些预处理
  // 在这里展示进度条
  instance.interceptors.request.use(config => {
    if(config.url !='api/index/orderspay' && config.url!='api/index/chargepay' && config.url!='api/index/ordersaspay' && config.url!='api/index/ordersbypay'){
      if (loadingInstance) {
        loadingInstance.close();
      }
      // 开启loading
      loadingInstance = Loading.service(options);
    }
   
    // NProgress.start();//可选
    // 为请求头对象，添加Token验证的Authorization字段
    config.headers.Authorization = localStorage.getItem('token');
    config.headers.token = localStorage.getItem('token');
    return config;
  });

  // 拦截响应。
  instance.interceptors.response.use(response => {
    // NProgress.done();
   
    loadingInstance.close();

    return response;
  }, error => {
    /***** 接收到异常响应的处理开始 *****/
    if (error && error.response) {
      // 1.公共错误处理
      // 2.根据响应码具体处理
      console.log(error);
      switch (error.response.status) {
        case 400:
          Message.error('错误请求')
          break;
        case 401:
          Message.error('请登录后操作')
          // setTimeout(() => {
          //   localStorage.clear()
          //   router.push('/login')
          // }, 500);
          break;
        case 403:
          Message.error('拒绝访问')
          break;
        case 404:
          Message.error('请求错误,未找到该资源')
          // window.location.href = "/NotFound"
          break;
        case 405:
          Message.error('请求方法未允许')
          break;
        case 408:
          Message.error('请求超时')
          break;
        case 500:
          Message.error('服务器端出错')
          break;
        case 501:
          Message.error('网络未实现')
          break;
        case 502:
          Message.error('网络未实现')
          error.message = '网络错误'
          break;
        case 503:
          Message.error('服务不可用')
          break;
        case 504:
          Message.error('网络超时')
          break;
        case 505:
          Message.error('http版本不支持该请求')
          break;
        default:
          Message.error(`连接错误${error.response.status}`)
      }
    } else {
      // 超时处理
      if (JSON.stringify(error).includes('timeout')) {
        Message.error('服务器响应超时，请刷新当前页')
      }
      Message.error('连接服务器失败')
    }
    // Message.error(error.message)
    /***** 处理结束 *****/
    //如果不需要错误处理，以上的处理过程都可省略
   
    loadingInstance.close();

    return Promise.resolve(error.response)
  });
  // export default instance
  return instance(config);
}

