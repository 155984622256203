// 4.7 防止多次点击多次跳转（函数节流）   
// throttle : 节流阀
// fn       : 事件处理
// gapTime  : 点击间隔时间
//使用方法   tap: util.throttle(function (e) {//点击事件},500)

export function throttle(fn, gapTime) {
	if (gapTime == null || gapTime == undefined) {
	  gapTime = 1000
	}
  
	let _lastTime = null
  
	// 返回新的函数
	return function () {
	  let _nowTime = +new Date()
	  if (_nowTime - _lastTime > gapTime || !_lastTime) {
		fn.apply(this, arguments) //将this和参数传给原函数
		_lastTime = _nowTime
	  }
	}
  }



// export function throttle(fn, delay) {
// 	let timer = null;
// 	return function(...args) {
// 	  if (timer) {
// 		return;
// 	  }
// 	  timer = setTimeout(() => {
// 		fn.apply(this, args);
// 		timer = null;
// 	  }, delay);
// 	};
//   }