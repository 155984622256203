import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios';
import ElementUI from 'element-ui';
import $ from 'jquery';
import 'element-ui/lib/theme-chalk/index.css';
Vue.prototype.$imgDomain = 'http://9527.cn'

Vue.prototype.$http = axios
Vue.config.productionTip = false

Vue.prototype.$jquery = $;
Vue.use(ElementUI);
Vue.filter('dateFormat', function (value) {
  let date = new Date(value * 1000);
  let year = date.getFullYear();
  let month = (date.getMonth() + 1 + '').padStart(2, '0');
  let day = (date.getDate() + '').padStart(2, '0');
  let hour = (date.getHours() + '').padStart(2, '0');
  let minute = (date.getMinutes() + '').padStart(2, '0');
  let second = (date.getSeconds() + '').padStart(2, '0');
  return `${year}-${month}-${day} `;
  // ${hour}:${minute}:${second}
})
Vue.filter('dateFormat2', function (value) {
  let date = new Date(value * 1000);
  let year = date.getFullYear();
  let month = (date.getMonth() + 1 + '').padStart(2, '0');
  let day = (date.getDate() + '').padStart(2, '0');
  let hour = (date.getHours() + '').padStart(2, '0');
  let minute = (date.getMinutes() + '').padStart(2, '0');
  let second = (date.getSeconds() + '').padStart(2, '0');
  return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
  // ${hour}:${minute}:${second}
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
