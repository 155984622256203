<template>
	<div class="side">
		<div class="list">
			<div
				v-for="(item,index) in nav_li"
				:key="index"
				class="li"
				@click="go_page(item.name,item.url)"
			>
				<img
					:src="item.imgurl"
					alt=""
				>
				<span>{{item.name}}</span>
			</div>

		</div>
		<el-backtop :visibility-height=height  :right=right :bottom=bottom>
			<div class="li_bottom">

				<img
					src="@/assets/top_img/73.png"
					alt=""
				>
				<span>TOP</span>

			</div>
		</el-backtop>
	</div>
</template>

<script>
import {open53} from "@/utils/CustomerService";
import {ConfigApi} from '@/work/public'
import { mapState } from 'vuex';
export default {
	name:'SideBar',
	data () {
		return {
			open53:"",
			height:-1,
			right:20,
			bottom:-30,
			con:'',
			nav_li: [
				{ name: "我要买", imgurl: require('@/assets/top_img/67.png'), url: 'my_going' },
				{ name: "我要卖", imgurl: require('@/assets/top_img/68.png'), url: 'sell_list' },
				{ name: "我要估价", imgurl: require('@/assets/top_img/69.png'), url: 'valuation' },
				{ name: "官方回收", imgurl: require('@/assets/top_img/70.png'), url: '' },
				{ name: "个人中心", imgurl: require('@/assets/top_img/71.png'), url: 'user' },
				{ name: "联系客服", imgurl: require('@/assets/top_img/72.png'), url: '' }
			]
		}
	},
	// computed: {
	// 	...mapState({
	// 		count: state => state.list.field_14021
	// 	})
	// },
	// watch: {
	// 	count (newVal, oldVal) {
	// 		this.open53=newVal
	// 	},
	// },
	created(){
		this.open53=localStorage.getItem('listid')
		this.getConfig()
	},
	methods: {
		go_page (name,url) {
			if(name=='联系客服' || name=='官方回收'){
				open53(this.open53)
				// window.open(this.con, '_blank'); //新开页面
			}else{
				this.$router.push({
				name: url
			})
			}
			
		},
		//配置信息
		getConfig(){
			ConfigApi().then(res=>{
			if(res.data.code==1){
				// console.log(res.data.data,'配置信息')
				this.con=res.data.data.code
			}
		})
		}
		
	}
}
</script>

<style lang='scss' scoped>
.side {
	width: 80px;
	height: 475px;
	background-image: url('@/assets/top_img/65.png');
	background-size: 100% 100%;
	background-repeat: no-repeat;
	position: fixed;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
	.list {
		height: 300px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		position: relative;
		right: 80%;
		top: 51%;
		transform: translateY(-50%);
		.li {
			background-image: url('@/assets/top_img/66.png');
			background-size: 100% 100%;
			background-repeat: no-repeat;
			width: 112px;
			height: 40px;
			display: flex;
			align-items: center;
			justify-content: center;
			font-weight: bold;
			cursor: pointer;
			img {
				width: 18px;
				height: 16px;
				margin-right: 5px;
			}
		}
	}
	.li_bottom {
		width: 41px;
		height: 74px;
		background-image: url('@/assets/top_img/74.png');
		background-size: 100% 100%;
		background-repeat: no-repeat;
		position: absolute;
		bottom: -7%;
		left: 50%;
		transform: translateX(-50%);
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		font-weight: 600;
		img {
			width: 27px;
			height: 18px;
			margin-bottom: 10px;
		}
		span{
			color: black;
			font-size: 16px;
		}
	}
}
</style>