<template>
	<div class="home">
		<div class="body">
			<!-- 轮播 -->
			<div class="center">
				<div class="center_left">
					<div
						class="center_left_text1"
						@click="to_page_show()"
					>我要卖</div>
					<router-link to="/my_going">
						<div class="center_left_text2">我要买</div>
					</router-link>
					<div
						class="center_left_text1"
						@click="go_kf()"
					>官方回收</div>
					<router-link to="/valuation">
						<div class="center_left_text2">专业估价</div>
					</router-link>
				</div>
				<div class="swiper">
					<!-- <img class="bg_img" src="@/assets/top_img/5.png" alt=""> -->
					<el-carousel
						trigger="click"
						:interval='5000'
					>
						<el-carousel-item
							v-for="(item,index) in imgs"
							:key="index"
						>
							<img
								:src="item.image"
								alt=""
							>

						</el-carousel-item>
					</el-carousel>
				</div>
			</div>
			<!-- 公告区域 -->
			<div class="announcement">
				<div class="announcement_left">
					<div
						class="announcement_left_t"
						@click="go_artcles('平台公告')"
					>
						<span>平台公告</span>
						<div>
							<span>更多</span>
							<img
								src="@/assets/top_img/6.png"
								alt=""
							>
						</div>
					</div>
					<ul>
						<li
							v-for="(item,index) in NoticeList"
							:key="index"
							@click="go_page(item.id)"
						>{{item.title}}</li>
					</ul>
				</div>
				<div class="announcement_center">
					<div class="announcement_center_news">
						<img
							src="@/assets/top_img/7.png"
							alt=""
						>
						<span class="yellow">{{neworder.second_name}}</span>
						<span class="numbering">订单编号：{{neworder.ordersn}}</span>
						<div>成交价：<span class="red">{{neworder.money}}元</span></div>
					</div>
					<div class="trade">
						<ul>
							<li
								v-for="(item,index) in TradingList"
								:key="index"
								@click="go_page(item.id)"
							>
								{{item.title}}
							</li>

							<img
								src="@/assets/top_img/79.png"
								@click="go_artcles('交易悉知')"
								alt=""
							>
						</ul>
					</div>
				</div>
				<div class="announcement_right">
					<div class="center_left_text1">
						<img
							src="@/assets/top_img/9.png"
							alt=""
						>
						<span @click="go_roster()">黑名单查询</span>
					</div>
					<div
						class="center_left_text2"
						@click="go_kf()"
					>
						<img
							src="@/assets/top_img/10.png"
							alt=""
						>
						<span>联系客服</span>
					</div>
					<div
						class="announcement_left"
						style="height: 220px;margin: 0 auto;"
					>
						<div
							class="announcement_left_t"
							@click="go_artcles('找回案例')"
						>
							<span>找回案例</span>
							<div>
								<span>更多</span>
								<img
									src="@/assets/top_img/6.png"
									alt=""
								>
							</div>
						</div>
						<ul style="height: 176px;">
							<li
								v-for="(item,index) in CaseList"
								:key="index"
								@click="go_page(item.id)"
							>{{item.title}}</li>
						</ul>
					</div>
				</div>
			</div>
			<!-- 通知 -->
			<div class="notice">
				<img
					src="@/assets/top_img/11.png"
					alt=""
				>
				<span>{{TipsText.text}}</span>
			</div>
			<!-- 列表 -->
			<div class="list">
				<ul class="picker_li">
					<li
						v-for="(item,index) in  GameCalss"
						:key="index"
						:class="gameIndex==index ? 'active':''"
						@click="gameSelect(item.id,index)"
					>
						{{item.name}}
					</li>
				</ul>
				<ul class="img_li">
					<li
						v-for="(item,index) in GameCalss2"
						:key="index"
						@click="to_my_router(item.name,item.id)"
					>
						<img
							:src="item.image"
							alt=""
						>
						<div>{{item.name}}</div>
					</li>
				</ul>
				<ul class="div_li">
					<li
						v-for="(item,index) in shopList"
						:key="index"
						@click="go_detail(item.id)"
					>
						<img
							:src="item.fm_img"
							alt=""
						>
						<div class="text">【{{item.goodsnum}}】{{item.title}}</div>

						<p>{{item.class.second_name}}-{{item.class.three_name}} <span v-if="item.class.four_name">-{{item.class.four_name}}</span> </p>
						<div class="label"> <span
								v-for="(it,ind) in item.label_name"
								:key="ind"
							>{{it}}</span></div>
						<div class="price">￥{{item.price}}</div>
					</li>

				</ul>
			</div>

		</div>
		<!-- 幕布 -->
		<div
			class="curtain"
			v-if="status"
		></div>
		<div
			class="bargain"
			v-if="status"
		>
			<div class="title">
				<span></span><span>出售类型</span><img
					src="@/assets/top_img/35.png"
					alt=""
					@click="to_page_show()"
				>
			</div>
			<div class="sell_class">
				<div
					class="sell_class_li"
					@click="to_page()"
				>
					<span>代售 </span>
					<p>商品上架至平台进行代售</p>
					<div><span>专属客服</span>引导进行操作交易</div>
				</div>
				<div
					class="sell_class_li other"
					@click="go_kf()"
				>

					<span>官方回收 </span>
					<p>到账快 客服在线估价回收</p>
					<div><span>签署合同回收 安全 快捷 有保障 </span></div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>

import { open53 } from "@/utils/CustomerService";
import {
	CarouselApi,
	ArticleListApi,
	ArticleClassApi,
	TipsApi,
	GameApi,
	ShopListApi,
	neworderApi,
	ConfigApi,
	SortallApi
} from '@/work/public.js'
import { throttle } from '@/utils/util'
export default {

	data () {
		return {
			imgs: [],
			// area_li: ['端游专区', '手游专区', '页游专区', '其他专区'],
			status: false,
			page: 1,           //当前页
			pagesize: 10,      //每页条数
			ArticleClass: [],  //文章分类

			CaseList: [],     //找回案例
			TradingList: [],  //交易悉知
			NoticeList: [],   //平台公告
			TipsText: '',     //温馨提示
			shopList: [],      //商品分类

			gameIndex: 0,      //游戏分类下标
			GameCalss: [],     //游戏分类

			GameCalss2: [],   //游戏分类二级

			imgurl: '',

			neworder: {}, //最新成交
			first_id: '',

			Config: {},  //配置信息

			open53: '',
		}
	},
	watch: {
		
	},
	mounted () {
		if(localStorage.getItem('loginop53')){
           window.location.reload();
		   localStorage.removeItem('loginop53')
		}
		// console.log(localStorage.getItem('listid'), 'localStorage')
		this.open53 = localStorage.getItem('listid')
		this.getArticleClass()   //文章分类
		this.getcarousel()       //轮播图
		this.getTips()           //温馨提示
		this.getGame()           //游戏分类
		this.getShopList()       //商品列表
		this.getneworder()       //最新成交
		this.getConfig()         //配置信息
		// this.getallclass()
	},
	methods: {
		go_kf () {
			open53()
			// window.open(this.Config.code, '_blank'); //新开页面
		},
		//配置信息
		getConfig () {
			ConfigApi().then(res => {
				if (res.data.code == 1) {
					this.Config = res.data.data
				}
			})
		},

		to_my_router (name, id) {
			this.$router.push({
				name: "my_going_list",
				query: {
					second_name: name,  //二级name
					first_id: this.first_id,  //一级
					second_id: id,  //二级

				}
			})
		},

		//详情页
		go_detail (id) {
			this.$router.push({
				name: 'my_going_deta',
				query: {
					id: id
				}
			})
		},
		//最新成交
		getneworder () {
			neworderApi().then(res => {
				if (res.data.code == 1) {
					this.neworder = res.data.data
				}
			})
		},

		//游戏分类切换
		gameSelect (id, index) {
			this.first_id = id
			this.gameIndex = index
			if (index == 0) {
				this.getallclass()
			} else {
				this.getGame2(id)
			}

		},
		//商品列表
		getShopList () {
			let obj = {
				operated: '',
				er_actualname: '',
				is_face: '',
				bargain_de: '',
				first_price: '',
				last_price: '',
				pagesize: 8,
				page: this.page,
				keyword: '',
			}
			ShopListApi(obj).then(res => {
				if (res.data.code == 1) {
					res.data.data.data.forEach((element, index) => {
						element.label_name = element.label_name.split(',')
					});

					this.shopList = res.data.data.data
					// console.log(res.data, '商品列表')
				}
			})
		},

		//游戏分类  一级
		getGame () {
			let obj = {
				pid: 0,
				// page: this.page,
				// pagesize: 99,
			}
			GameApi(obj).then(res => {
				if (res.data.code == 1) {
					let data = {
						id: '全部',
						name: '全部'
					}
					res.data.data.data.unshift(data)
					this.GameCalss = res.data.data.data
					this.getallclass()
					// console.log('游戏分类', res.data.data)
					// this.getGame2(res.data.data.data[0].id)
				}
			})
		},
		//游戏分类  二级
		getGame2 (id) {
			this.GameCalss2 = []
			let obj = {
				pid: id,
				page: this.page,
				pagesize: 99,
			}
			GameApi(obj).then(res => {
				if (res.data.code == 1) {
					this.GameCalss2 = res.data.data.data
					// console.log('游戏分类二级', res.data.data)
				}
			})
		},

		//温馨提示
		getTips () {
			TipsApi().then(res => {
				if (res.data.code == 1) {
					this.TipsText = res.data.data
				}
			})
		},
		//文章详情页
		go_page (id) {
			this.$router.push({
				name: "articles_deta",
				params: {
					id: id
				}
			})
		},
		// 
		//文章分类
		getArticleClass () {
			ArticleClassApi().then(res => {
				if (res.data.code == 1) {
					let arrData = res.data.data
					this.ArticleClass = arrData
					this.getArticleList(arrData[3].id)    //文章列表  找回案例
					this.getArticleList(arrData[2].id)    //文章列表  交易悉知
					this.getArticleList(arrData[1].id)    //文章列表  平台公告
				}
			})
		},

		//文章列表
		getArticleList (id) {
			let obj = {
				classid: id,
				page: this.page,
				pagesize: this.pagesize,
			}
			ArticleListApi(obj).then(res => {
				if (res.data.code == 1) {
					let arrData = res.data.data.data
					if (id == 1) {
						return this.CaseList = arrData  //找回案例
					}
					if (id == 2) {
						return this.TradingList = arrData  //交易悉知
					}
					if (id == 3) {
						return this.NoticeList = arrData  //平台公告
					}
					// console.log(res.data)
				}
			})
		},
		//轮播图
		getcarousel () {
			let obj = {
				classid: 1
			}
			CarouselApi(obj).then(res => {
				if (res.data.code == 1) {
					this.imgs = res.data.data

				}
			})
		},

		//查询黑名单
		go_roster () {
			this.$router.push('/roster');
		},
		//文章列表
		go_artcles (name) {
			this.$router.push({
				name: 'list_articles',
				query: {
					type: name
				}

			});
		},
		to_page_show () {
			this.status = !this.status
		},
		to_page () {
			this.$router.push({
				name: 'sell_list'
			});
		},

		//获取全部分类
		getallclass () {
			let obj = {
				page: 1,
				pagesize: 999,
				name: ''
			}
			SortallApi(obj).then(res => {
				if (res.data.code == 1) {
					this.GameCalss2 = res.data.data.data
					console.log('获取全部分类', res.data.data)
				}
			})
		}

	}
}
</script>
<style  lang='scss' scoped>
.home {
	width: 100%;
	min-height: 100vh;
	background-image: url('@/assets/top_img/12.jpg');
	background-size: 100% 100%;
	background-repeat: no-repeat;
	position: relative;
	.body {
		width: 1230px;
		margin: 0 auto;
		padding: 0 0 50px 0;
		.center {
			width: 100%;
			padding: 10px 0 0 0;
			display: flex;
			justify-content: space-between;
			.center_left {
				width: 310px;
				height: 440px;

				background-image: url('@/assets/top_img/1.png');
				background-size: 100% 100%;
				background-repeat: no-repeat;
				display: flex;
				flex-direction: column; /* 设置为垂直布局 */
				justify-content: space-between;
				padding: 50px 0;
				box-sizing: border-box;
				.center_left_text1 {
					background-image: url('@/assets/top_img/2.png');
					background-size: 100% 100%;
					background-repeat: no-repeat;
					width: 254px;
					height: 86px;
					margin: 0 auto;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 18px;
					color: #ffffff;
					font-weight: 600;
					cursor: pointer;
				}
				.center_left_text2 {
					background-image: url('@/assets/top_img/3.png');
					background-size: 100% 100%;
					background-repeat: no-repeat;
					width: 254px;
					height: 86px;
					margin: 0 auto;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 18px;
					color: #ffffff;
					font-weight: 600;
				}
			}
			.swiper {
				width: 888px;
				height: 440px;
				// background-image: url('@/assets/top_img/5.png');
				// background-size: 100% 100%;
				// background-repeat: no-repeat;
				display: flex;
				align-items: center;
				justify-content: center;
				position: relative;
				overflow: hidden;
				.bg_img {
					width: 888px;
					height: 440px;
					position: absolute;
					// z-index: 999;
					// box-shadow: 15px 15px 15px 15px #1f06e0;
				}
			}
		}
		.announcement {
			display: flex;
			// align-items: center;
			margin: 20px 0;
			.announcement_left {
				width: 220px;
				height: 392px;
				background: linear-gradient(180deg, #c900fc 0%, #861ff5 100%);
				border-radius: 14px 14px 14px 14px;

				.announcement_left_t {
					display: flex;
					align-items: center;
					justify-content: space-between;
					padding: 0 13px;
					height: 44px;
					cursor: pointer;
					span {
						font-size: 16px;
						color: #ffffff;
						font-weight: bold;
					}
					div {
						span {
							font-weight: 400;
							font-size: 14px;
						}
						img {
							width: 5px;
							height: 10px;
						}
					}
				}
				ul {
					width: 95%;
					height: 348px;
					margin: 0 auto;
					overflow: hidden;
					display: flex;
					flex-direction: column; /* 设置为垂直布局 */
					justify-content: space-between;
					padding: 0 0 5px 0;
					box-sizing: border-box;
					li {
						width: 192px;
						height: 24px;
						color: white;
						overflow: hidden;
						display: flex;
						flex-wrap: nowrap;
						padding: 5px 0;
						line-height: 24px;
						// margin: 11px 0;
						border-bottom: 1px solid rgba(255, 255, 255, 0.459);
						font-size: 14px;
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 1; //行数
						cursor: pointer;
					}
				}
			}
			.announcement_center {
				width: 758px;
				margin: 0 auto;
				.announcement_center_news {
					width: 722px;
					height: 42px;
					background: #201e43;
					border-radius: 8px 8px 8px 8px;
					border: 1px solid #fef87f;
					display: flex;
					align-items: center;
					justify-content: space-between;
					padding: 0 23px;
					box-sizing: border-box;
					margin: 0 auto;
					img {
						width: 94px;
						height: 24px;
						margin: 7px 0 0 0;
					}
					.red {
						color: #e54c5e;
					}
					.yellow {
						color: #fef87f;
						font-weight: bold;
						font-size: 16px;
					}
					.numbering {
						color: white;
						font-weight: bold;
						font-size: 16px;
					}
					div {
						color: white;
						font-size: 16px;
						font-weight: bold;
					}
				}
				.trade {
					width: 758px;
					height: 350px;
					background-image: url('@/assets/top_img/8.png');
					background-size: 100% 100%;
					background-repeat: no-repeat;
					position: relative;
					ul {
						width: 100%;
						// width: 650px;
						height: 250px;
						margin: 10px auto;
						display: flex;
						flex-direction: column;
						// justify-content: space-between;
						position: absolute;
						padding: 0 30px;
						box-sizing: border-box;
						color: white;
						top: 20%;
						// overflow: hidden; /* 隐藏不需要的滚动条 */
						// overflow-y: scroll; /* 仅显示垂直滚动条 */

						img {
							position: absolute;
							width: 20px;
							height: 63px;
							right: 2.2%;
							top: 48%;
							transform: translateY(-50%);
						}
						li {
							width: 95%;
							text-align: left;
							font-size: 14px;
							padding: 5px 0;
							border-bottom: 1px solid rgb(255 255 255 / 15%);
							overflow: hidden;
							-webkit-line-clamp: 1;
							text-overflow: ellipsis;
							display: -webkit-box;
							-webkit-box-orient: vertical;
							cursor: pointer;
							height: 20px;
							// margin: 5px 0;
						}
					}
				}
			}
			.announcement_right {
				.center_left_text1 {
					background-image: url('@/assets/top_img/2.png');
					background-size: 100% 100%;
					background-repeat: no-repeat;
					width: 254px;
					height: 86px;
					margin: 0 auto;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 18px;
					color: #ffffff;
					font-weight: 600;
					cursor: pointer;
					img {
						width: 20px;
						height: 20px;
						margin-right: 10px;
					}
				}
				.center_left_text2 {
					background-image: url('@/assets/top_img/3.png');
					background-size: 100% 100%;
					background-repeat: no-repeat;
					width: 254px;
					height: 86px;
					margin: 0 auto;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 18px;
					color: #ffffff;
					font-weight: 600;
					cursor: pointer;
					img {
						width: 20px;
						height: 20px;
						margin-right: 10px;
					}
				}
			}
		}
		.notice {
			width: 100%;
			height: 42px;
			background: #fef87f;
			border-radius: 8px 8px 8px 8px;
			display: flex;
			align-items: center;
			font-weight: bold;
			color: #e54c5e;
			font-size: 16px;
			padding: 0 34px;
			box-sizing: border-box;
			img {
				width: 94px;
				height: 24px;
				margin-top: 7px;
				margin-right: 30px;
			}
		}
		.list {
			width: 100%;
			min-height: 1300px;
			background-image: url('@/assets/top_img/15.png');
			background-size: 100% 100%;
			background-repeat: no-repeat;
			position: relative;

			.picker_li {
				min-width: 35%;
				max-width: 75%;
				display: flex;
				align-items: center;
				justify-content: space-between;
				flex-wrap: wrap;
				font-size: 18px;
				color: white;
				position: absolute;
				left: 7%;
				top: 3.5%;
				li {
					padding-bottom: 5px;
					// width: 100%;
					font-weight: 600;
					text-align: left;
					// margin-right: 120px;
					cursor: pointer;
				}
				.active {
					color: #fef87f;
					font-size: 20px;
					border-image: linear-gradient(
							to right,
							rgba(255, 255, 255, 0) 25%,
							#fef87f 25%,
							#fef87f 75%,
							rgba(255, 255, 255, 0) 75%
						)
						1;
					border-bottom: 2px solid #fef87f;
				}
			}
			::-webkit-scrollbar {
				// display: none;
				width: 12px; /* 设置滚动条的宽度 */
				height: 100%;
			}
			.img_li {
				width: 90%;
				height: 310px;
				display: flex;
				// align-items: center;
				flex-wrap: wrap;
				// justify-content: space-between;
				position: absolute;
				left: 50%;
				top: 8%;
				transform: translateX(-50%);
				color: white;
				overflow-y: scroll;

				li {
					width: 12%;
					// padding: 10px 30px;
					margin: 17px 2px;
					cursor: pointer;
					box-sizing: border-box;
					div {
						text-align: center;
					}
				}
				img {
					width: 76px;
					height: 76px;
					margin: 5px auto;
					display: block;
					border-radius: 10px 10px;
				}
			}
			.div_li {
				width: 90%;
				display: flex;
				// align-items: center;
				flex-wrap: wrap;
				// justify-content: space-between;
				position: absolute;
				left: 50%;
				top: 35%;
				transform: translateX(-50%);
				li {
					background-image: url('@/assets/top_img/13.png');
					background-size: 100% 100%;
					background-repeat: no-repeat;
					width: 23%;
					// height: 338px;
					padding: 36px 22px 25px 22px;
					box-sizing: border-box;
					margin: 17px 10px;
					cursor: pointer;
					img {
						width: 100%;
						height: 133px;
					}
					.text {
						margin: 10px 0;
						color: white;
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 2; //行数
					}
					p {
						font-size: 12px;
						color: #b4aafc;
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 1; //行数
					}
					.price {
						background-image: url('@/assets/top_img/14.png');
						background-size: 100% 100%;
						background-repeat: no-repeat;
						width: 98px;
						height: 43px;
						float: right;
						display: flex;
						// align-items: center;
						justify-content: center;
						padding: 5px 0 0 0;
						box-sizing: border-box;
						color: #fef87f;
						font-weight: bold;
						margin: 10px 0 0 0;
					}
					.label {
						display: flex;
						align-items: center;
						margin: 10px 0;
						flex-wrap: wrap;
						span {
							// width: 42px;
							padding: 2px 5px;
							height: 14px;
							background: linear-gradient(
								120deg,
								#fdf2da 0%,
								#fcd5b4 100%
							);
							font-size: 8px;
							color: #66371d;
							display: flex;
							align-items: center;
							justify-content: center;
							border-radius: 2px 2px 2px 2px;
							margin: 2px 2px;
						}
					}
				}
			}
		}
	}

	.bargain {
		width: 890px;
		height: 400px;
		position: fixed;
		left: 50%;
		transform: translateX(-50%);
		top: 20%;
		background-image: url('@/assets/top_img/36.png');
		background-size: 100% 100%;
		background-repeat: no-repeat;
		padding: 60px 40px;
		box-sizing: border-box;
		z-index: 999;
		.title {
			display: flex;
			align-items: center;
			justify-content: space-between;
			color: white;
			font-size: 26px;
			font-weight: bold;
			img {
				width: 25px;
				height: 25px;
			}
		}
		.sell_class {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 50px 20px 0;
			.sell_class_li {
				width: 360px;
				height: 180px;
				border-radius: 10px 10px 10px 10px;
				border: 2px solid #e7a6f3;
				padding: 27px 31px;
				box-sizing: border-box;
				background: linear-gradient(90deg, #c900fc 0%, #491c8b 100%);
				span {
					color: white;
					font-size: 20px;
					font-weight: bold;
				}
				p {
					margin: 30px 0 20px;
					color: #e7a6f3b7;
					font-size: 16px;
					font-weight: bold;
				}
				div {
					font-size: 16px;
					color: #e7a6f3b7;
					font-weight: 600;
					span {
						font-size: 16px;
						color: #fef87f;
					}
				}
			}
			.other {
				background: #ddefff00;
				border: 2px solid #ddefff79;
			}
		}
	}
	::v-deep .el-carousel__container {
		width: 100% !important;
		height: 100% !important;
	}
	::v-deep .el-carousel {
		width: 96% !important;
		height: 96% !important;
	}
	.el-carousel__item img {
		width: 100%;
		height: 100%;
		border-radius: 10px;
	}
}
</style>
