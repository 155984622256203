<template>
	<div class="home">
		<div class="head">
			<div class="top">
				<div class="top_center">
					<div class="top_left">您好，欢迎来到悟空游戏账号服务网！</div>
					<div
						class="top_right"
						v-if="!token"
					>
						<div
							class="top_right_y"
							@click="go_page(1)"
						>
							【请登录】
						</div>
						或 <div
							class="top_right_y"
							@click="go_page(2)"
						>
							【注册】
						</div>
					</div>
					<div
						class="top_right"
						v-else
						@click="go_page(3)"
					>
						<img
							class="userImg"
							:src="user.avatar"
							alt=""
						>
						<span>个人中心</span>
						<!-- <img
							src="@/assets/top_img/76.png"
							alt=""
						> -->
						<div
							class="exit"
							@click="exit()"
						>
							退出登录
						</div>
					</div>
				</div>
			</div>
			<div class="center">
				<div class="center_view">
					<div class="center_input">
						<router-link to="/">
							<img
								src="@/assets/top_img/logo.png"
								alt=""
							>

						</router-link>
						<div
							class="input"
							v-if="goodsShow"
						>

							<div
								@click="selectshow()"
								class="selectbox"
							>{{ gameName ? gameName : '选择游戏' }}</div>
							<input
								type="text"
								placeholder="请输入商品编号/商品标题"
								v-model="serachKeword"
							>
							<div
								class="search"
								@click="search()"
							>搜索</div>

						</div>
						<div
							class="GoodsRight"
							v-else
						>
							订单确认
						</div>

					</div>
					<div
						class="nav"
						v-if="goodsShow"
					>
						<nav>
							<router-link to="/">首页</router-link>
							<router-link to="/sell_list">我要卖</router-link>
							<router-link to="/my_going">我要买</router-link>
							<!-- <router-link :to="con" target="_blank">官方回收</router-link> -->
							<span
								style="color:white;font-weight:600;	cursor: pointer;width: 150px;text-align:center"
								@click="goblanck()"
							>官方回收</span>
							<router-link :to="{
								 name:'sell_list',
								 query:{
								     evaluate:true
								    }
								 }">专业估价</router-link>
							<router-link :to="{
								name:'list_articles',
								params:{
									type:'1'
								}
							}">平台公告</router-link>
							<router-link to="/user" style="position: relative;" ><span :class="{'menu-item-dot': msgnum>0  }"></span> 个人中心 </router-link>
							
						</nav>
					</div>
				</div>
			</div>
		</div>
		<div
			class="curtain"
			v-if="mushow"
			@click="selectshow2()"
		>

		</div>
		<div
			class="selectList"
			v-if="mushow"
		>
			<div class="nav_box">
				<div
					v-for="(item,index) in GameCalss"
					:key="index"
					:class="gameIndex==index ? 'topactive':''"
					@click="classSelct(item.id,index)"
				>{{item.name}}
				</div>
			</div>
			<div class="nav_box2">
				<div
					v-for="(item,index) in GameCalss2"
					:key="index"
					:class="nav_box2Index==index ? 'nav_box2active':''"
					@click="go_class(item.name,item.id,index)"
				>

					{{item.name}}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { open53 } from "@/utils/CustomerService";
import { InfoApi, msgnumApi } from "@/work/user"
import { GameApi, ConfigApi } from "@/work/public"
import { mapState } from 'vuex';
import { throttle } from '@/utils/util'


export default {
	name: 'top',
	data () {
		return {
			token: "",
			UserShow: true,
			goodsShow: true,
			user: [],
			mushow: false,
			GameCalss: [],
			GameCalss2: [],
			keword: '',   //搜索
			first_id: '',//一级id
			page: 1,
			pagesize: 99999,
			gameIndex: 0,
			gameName: '', //游戏名字
			nav_box2Index: -1,
			second_name: '',
			second_id: '',
			serachKeword: '',
			con: '',

			open53: "",

			msgnum:0,
		}
	},
	computed: {
		...mapState({
			count: state => state.type,
			// count2: state => state.list.field_14021
		})
	},
	watch: {
		// count2(newVal, oldVal){
		// 	this.open53=newVal
		// },
		count (newVal, oldVal) {
			this.getUser()
		},
		//监听路由变化， 拿到传的参数
		$route (to, from, next) {

			this.getmsgnum()
			if (to.name == 'stages') {
				this.goodsShow = false
			} else {
				this.goodsShow = true
			}

		}

	},
	created () {
		this.open53 = localStorage.getItem('listid')
		if (localStorage.getItem('token')) {
			this.token = localStorage.getItem('token')
			this.getmsgnum()
			this.getUser()
		}
		
		this.getConfig()
	},
	methods: {

		//获取提醒数量getmsgnum
		getmsgnum () {
			this.msgnum=0
			msgnumApi().then(res => {
				if (res.data.code == 1) {
					let item=res.data.data
					this.msgnum=Number(item.numsf) +Number(item.numsh) +Number(item.numso) + Number(item.numst)+Number(item.numsv)
				
					// this.nav_list[6].children[0].num = res.data.data.numso
					localStorage.setItem('msgnum', res.data.data)
					// console.log(res.data.data, '获取提醒数量')
				}
			})
		},
		goblanck () {
			open53(this.open53)
			// window.open(this.con, '_blank'); //新开页面
		},
		//配置信息
		getConfig () {
			ConfigApi().then(res => {
				if (res.data.code == 1) {
					// console.log(res.data.data, '配置信息')
					this.con = res.data.data.code
				}
			})
		},
		selectshow2 () {
			this.mushow = false
		},
		//退出登录
		exit: throttle(function () {

			localStorage.clear();
			this.$router.push('/login')
			// window.location.reload();
			// this.$message({
			// 	message: '退出成功',
			// })
		}, 1500),
		//跳转三级分类
		go_class (name, id, index) {
			this.nav_box2Index = index
			this.gameName = name
			this.mushow = !this.mushow
			this.second_name = name
			this.second_id = id  //二级
		},
		//搜索
		search () {
			if (!this.second_id) {
				this.$message.error('请先选择游戏')
				return
			}
			this.$router.push({
				name: "my_going_list",
				query: {
					second_name: this.second_name,  //二级name
					first_id: this.first_id,  //一级
					second_id: this.second_id,  //二级
					serachKeword: this.serachKeword
				}
			})
		},

		classSelct (id, index) {
			this.first_id = id
			this.gameIndex = index
			this.getGame2(id)

		},
		//游戏分类  一级
		getGame () {
			let obj = {
				pid: 0,
				page: this.page,
				pagesize: 4,
			}
			GameApi(obj).then(res => {
				if (res.data.code == 1) {
					this.GameCalss = res.data.data.data
					this.getGame2(res.data.data.data[0].id)
				}
			})
		},
		//游戏分类  二级
		getGame2 (id) {
			// this.GameCalss2 = []
			let obj = {
				pid: id || this.first_id,
				page: this.page,
				pagesize: this.pagesize,
				name: this.keword
			}
			GameApi(obj).then(res => {
				if (res.data.code == 1) {
					this.GameCalss2 = res.data.data.data
				}
			})
		},
		selectshow () {
			this.getGame()
			this.mushow = !this.mushow
		},
		//获取用户信息
		getUser () {

			InfoApi().then(res => {
				if (res.data.code == 1) {
					this.user = res.data.data

					// console.log(res.data.data, 'user')
				} else {

				}
			})
		},
		go_page (index) {
			if (index == 1) {

				this.$router.push({
					name: "login"
				})
			} else if (index == 2) {
				this.$router.push({
					name: "enroll"
				})
			} else {
				this.$router.push({
					name: "user"
				})
			}

		}
	}

}
</script>
<style  lang='scss' scoped>
#app {
	position: relative;
	.selectList {
		width: 1230px;
		min-height: 200px;
		background: linear-gradient(145deg, #c900fc 0%, #491c8b 100%);
		border: 1px solid #664ced;
		position: absolute;
		top: 140px;
		left: 50%;
		transform: translateX(-50%);
		border-radius: 10px;
		z-index: 999;
		padding: 20px;
		box-sizing: border-box;
	}
	.nav_box {
		width: 70%;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		color: white;
		div {
			min-width: 100px;
			font-size: 16px;
			cursor: pointer;
		}
		.topactive {
			font-size: 18px !important;
			color: yellow !important;
			font-weight: 600;
		}
	}
	.nav_box2 {
		display: flex;
		flex-wrap: wrap;
		margin: 15px 0 0 0;
		div {
			padding: 3px 5px;
			border-radius: 15px;
			border: 1px solid #ffffff;
			background: white;
			margin: 3px 5px;
			font-size: 14px;
			cursor: pointer;
		}
		.nav_box2active {
			background: yellow;
		}
	}
}

.top {
	width: 100%;
	background: #262626;
	height: 42px;
	display: flex;
	align-items: center;

	.top_center {
		width: 1230px;
		margin: 0 auto;
		display: flex;
		align-items: center;
		justify-content: space-between;
		.top_left {
			color: #8d8d8d;
			font-size: 14px;
		}
		.top_right {
			display: flex;
			align-items: center;
			color: #8d8d8d;
			font-size: 14px;
			cursor: pointer;
			.top_right_y {
				color: #fef87f;
				cursor: pointer;
			}
			img {
				width: 10px;
				height: 6px;
			}
			.userImg {
				width: 26px;
				height: 26px;
				border-radius: 50% 50%;
			}
			span {
				padding: 0 10px;
			}
			.exit {
				font-size: 14px;
				margin: 10px;
			}
		}
	}
}
.center {
	width: 100%;
	margin: 0 auto;
	background: #171719;
	.center_view {
		width: 1230px;
		margin: 0 auto;
		padding: 10px 0;
		.center_input {
			display: flex;
			align-items: center;
			justify-content: space-between;
			overflow: hidden;
			position: relative;

			img {
				width: 275px;
				height: 74px;
			}
			.input {
				width: 534px;
				height: 40px;
				display: flex;
				align-items: center;
				// justify-content: spac;
				background: #fef87f;
				border-radius: 4px 4px 4px 4px;
				border: 2px solid #fef87f;
				cursor: pointer;
				.selectbox {
					min-width: 100px;
					display: flex;
					align-items: center;
					justify-content: center;
					padding: 0 10px;
				}
				input {
					height: 95%;
					width: 400px;
					border: 0;
					font-size: 14px;
					padding: 0 10px;
					box-sizing: border-box;
				}

				input:focus {
					outline: none;
					border: none;
				}
				.search {
					width: 90px;
					height: 100%;
					display: flex;
					align-items: center;
					justify-content: center;
					background: #fef87f;
					border-radius: 0px 4px 4px 0px;
					font-weight: bold;
					font-size: 16px;
					cursor: pointer;
				}
			}
		}
		.nav {
			nav {
				width: 100%;
				height: 45px;
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin: 10px 0;
				background-image: url('@/assets/top_img/nav.png');
				background-repeat: no-repeat;
				background-size: 100% 100%;
				a {
					width: 150px;
					height: 100%;
					font-weight: bold;
					color: #ffffff;
					text-align: center;
					display: block;
					line-height: 45px;
					font-size: 16px;

					&.router-link-exact-active {
						color: #171719;
						background-image: url('@/assets/top_img/nav_img.png');
						background-repeat: no-repeat;
						background-size: 100% 100%;
					}
				}
			}
		}
	}
	.GoodsRight {
		color: white;
		width: 90%;
		text-align: left;
		margin-left: 20px;
		padding-left: 20px;
		border-left: 1px solid white;
	}
}
.menu-item-dot {
	position: relative;
}

.menu-item-dot::after {
	content: '';
	position: absolute;
	top: -5px; /* 调整位置以适应你的布局 */
	right: -85px; /* 调整位置以适应你的布局 */
	width: 10px; /* 调整大小以适应你的设计 */
	height: 10px; /* 调整大小以适应你的设计 */
	background-color: red; /* 红点颜色 */
	border-radius: 50%; /* 使背景色呈现为圆形 */
}
</style>
