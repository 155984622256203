// import request from "./request";  全局引入   //这是个大坑 别这样引入

// import {request} from "@/work/request";     //这两个都能用
import { request } from "./request"
import JSEncrypt from 'jsencrypt';
const encrypt = new JSEncrypt();
encrypt.setPublicKey('MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAu4kKGFxZsxjES1zL+UzSBuv5fxjAtqPvg7dx/hrxeQR1emGYherxf/RWrW2EPHtTo0MUtaXL5YVtUcdaFuspYGXCsA97oAHNMRfKNnwJapJzLLRYRBn0a0u/y/kfWM5k7UQkeRAOtV7D7m89ADGEMUUPeSRdHFH3ITiaQE3XX9DktauvhhgtnHficS/XHEa0yrHxHrYNrJqgT3i7PJYDD50PxnHnboERviNOTZWrk0D5jIi+zmgb4HfDzSzv8X0zzWC8PUuDfVbSwDwqHLTA1wFZsEG/xtfzD0w5AIXzoyavxwzaZbJBnbZu5Gkrkfl4VZrdnGJgRh0V0U/Z5pX6BwIDAQAB');//设置公有key
//用户信息
export function InfoApi (loginData) {
	return request({
		url: 'api/user/info',
		method: 'get',
		data: loginData
	})
}
//保存个人信息
export function InfoSaveApi (loginData) {
	return request({
		url: 'api/user/infoSave',
		method: 'post',
		data: loginData
	})
}
//修改密码
export function EditPwdApi (loginData) {
	return request({
		url: 'api/user/editpwd',
		method: 'post',
		data: loginData
	})
}
//提交账号认证
export function SubmitOfrealNameApi (loginData) {
	return request({
		url: 'api/hrupaccreditation/submitofrealname',
		method: 'post',
		data: loginData
	})
}

// 提交实名认证
export function SubmitOfIdCardApi (loginData) {
	return request({
		url: 'api/hrupaccreditation/submitofidcard',
		method: 'post',
		data: loginData
	})
}
// 提交包赔认证
export function SubmitOfIndemnifyApi (loginData) {
	return request({
		url: 'api/hrupaccreditation/submitofindemnify',
		method: 'post',
		data: loginData
	})
}

//商品浏览记录
export function BrowseListApi (loginData) {
	return request({
		url: 'api/Hrbrowsingrecords/list',
		method: 'post',
		data: loginData
	})
}

//余额充值
export function ChargeApi (loginData) {
	return request({
		url: 'api/money/charge',
		method: 'post',
		data: loginData
	})
}

//余额提现
export function DrawApi (loginData) {
	return request({
		url: 'api/money/draw',
		method: 'post',
		data: loginData
	})
}
//我的订单列表
export function MyListsApi (loginData) {
	return request({
		url: 'api/orders/lists',
		method: 'post',
		data: loginData
	})
}

//我的商品列表
export function GoodsListsApi (loginData) {
	return request({
		url: 'api/orders/goodslists',
		method: 'post',
		data: loginData
	})
}

//获取评估列表
export function OrderasListsApi (loginData) {
	return request({
		url: 'api/hrorderas/orderaslists',
		method: 'post',
		data: loginData
	})
}

//获取收藏
export function HrusershopApi (loginData) {
	return request({
		url: 'api/Hrusershop/list',
		method: 'post',
		data: loginData
	})
}

//我的订单详情
export function OrdersApi (loginData) {
	return request({
		url: 'api/orders/show',
		method: 'post',
		data: loginData
	})
}

//我的余额，商品数量，收藏商品数量
export function MoneyNumApi (loginData) {
	return request({
		url: 'api/orders/moneynum',
		method: 'post',
		data: loginData
	})
}

//编辑商品提交
export function EditApi (loginData) {
	// loginData= JSON.stringify(loginData)
	// loginData=encrypt.encrypt(loginData)
	loginData.shop_account=encrypt.encrypt(loginData.shop_account); //加密
	loginData.shop_psd=encrypt.encrypt(loginData.shop_psd); //加密
	return request({
		url: 'api/goods/edit',
		method: 'post',
		data: loginData
	})
}

//我的商品下架商品
export function IsOffApi (loginData) {
	return request({
		url: 'api/goods/isoff',
		method: 'post',
		data: loginData
	})
}


//我的商品删除商品
export function DelApi (loginData) {
	return request({
		url: 'api/goods/del',
		method: 'post',
		data: loginData
	})
}


//我的商品顶一顶
export function TopsApi (loginData) {
	return request({
		url: 'api/goods/tops',
		method: 'post',
		data: loginData
	})
}

//我的商品置顶
export function TopApi (loginData) {
	return request({
		url: 'api/goods/top',
		method: 'post',
		data: loginData
	})
}

//交易明细
export function moneyLogApi (loginData) {
	return request({
		url: 'api/orders/moneylog',
		method: 'post',
		data: loginData
	})
}

//我的砍价商品订单列表
export function CutListsApi (loginData) {
	return request({
		url: 'api/orders/cutlists',
		method: 'post',
		data: loginData
	})
}


//砍价订单-商家拒绝
export function AddcutreFusesApi (loginData) {
	return request({
		url: 'api/orders/addcutrefuses',
		method: 'post',
		data: loginData
	})
}

//砍价订单-商家确认
export function AddcutoksApi (loginData) {
	return request({
		url: 'api/orders/addcutoks',
		method: 'post',
		data: loginData
	})
}

//砍价订单-商家还价
export function AddcutbsApi (loginData) {
	return request({
		url: 'api/orders/addcutbs',
		method: 'post',
		data: loginData
	})
}

//我的商品详情
export function DetailApi (loginData) {
	return request({
		url: 'api/goods/detail',
		method: 'post',
		data: loginData
	})
}


//获取评估详情
export function OrderasDetailApi (loginData) {
	return request({
		url: 'api/hrorderas/orderasdetail',
		method: 'post',
		data: loginData
	})
}

//修改手机号
export function editmobileApi (loginData) {
	return request({
		url: 'api/user/editmobile',
		method: 'post',
		data: loginData
	})
}
//客服消息
export function msglistsApi (loginData) {
	return request({
		url: 'api/orders/msglists',
		method: 'post',
		data: loginData
	})
}

//头像列表
export function avatarlistsApi (loginData) {
	return request({
		url: 'api/orders/avatarlists',
		method: 'post',
		data: loginData
	})
}

//提醒数量
export function msgnumApi (loginData) {
	return request({
		url: 'api/orders/msgnum',
		method: 'get',
		data: loginData
	})
}

  //其实，也不一定就是params，也可以是 query 还有 data 的呀！
//params是添加到url的请求字符串中的，用于get请求。会将参数加到 url后面。所以，传递的都是字符串。无法传递参数中含有json格式的数据
//而data是添加到请求体（body）中的， 用于post请求。添加到请求体（body）中，json 格式也是可以的。