<template>
	<div class="home">
		<div class="home_div">
			<div class="foot_left">
				<router-link to="/">
					<img
						src="@/assets/top_img/logo.png"
						alt=""
					>
				</router-link>
				<div class="foot_left_div">
					<div v-for="(item,index) in singList" :key="index">
						<p @click="go_page(item.id,item.name)">{{item.name}}</p>
						<span>|</span>
						
					</div>
				</div>
			</div>
			<div class="foot_center">
				<div class="foot_center_t">
					<p>支付方式</p>
					<img
						src="@/assets/top_img/16.png"
						alt=""
					>
					<img
						src="@/assets/top_img/17.png"
						alt=""
					>
				</div>
				<div class="foot_center_b">
					<p>关注我们</p>
					<div>
						<img v-for="(item,index) in about" :key="index"
							:src="item.img"
							alt=""
							@click="go_page2(item.id,item.name)"
						>
						<!-- <img
							src="@/assets/top_img/19.png"
							alt=""
						>
						<img
							src="@/assets/top_img/20.png"
							alt=""
						>
						<img
							src="@/assets/top_img/21.png"
							alt=""
						>
						<img
							src="@/assets/top_img/22.png"
							alt=""
						> -->
					</div>

				</div>
			</div>
			<div class="foot_right">
				<img
					src="@/assets/top_img/23.png"
					alt=""
				>
				<img
					src="@/assets/top_img/24.png"
					alt=""
				>
				<img
					src="@/assets/top_img/25.png"
					alt=""
				>
			</div>
		</div>
		<div class="foot_b">
			<p>COPYRIGHT © 1998 – 2023 TENCENT. ALL RIGHTS RESERVED.</p>
			<p> 悟空游戏服务网 版权所有</p>
			<div v-html="con"></div>
		</div>
	</div>
</template>

<script>
import { SingLepageApi ,aboutApi,ConfigApi } from "@/work/public"
export default {
	name: 'foot',
	data () {
		return {
			type: true,
			singList:[],  
			about:[],
			con:{}
		}
	},
	created () {
		let parameter = this.$route.params
		// console.log(parameter, 'parameter')
		this.getSingLepage()   //单页面列表
		this.getabout()
		this.getConfig()
	},
	watch: {
		//监听路由变化， 拿到传的参数
		$route (to, from, next) {
			if (to.params.type) {

			}
			// console.log(to, from, next, 'next')
		}
	},
	methods: {
		//配置信息
		getConfig(){
			ConfigApi().then(res=>{
			if(res.data.code==1){
				// console.log(res.data.data,'配置信息')
				this.con=res.data.data.wukong_beian
			}
		})
		},
		//单页面列表
		getSingLepage () {
			SingLepageApi().then(res => {
				if(res.data.code==1){
					this.singList=res.data.data
					// console.log(res.data.data,'12321')
				}
			})
		},
		//关于我们
		getabout(){
			aboutApi().then(res=>{
			if(res.data.code==1){
				this.about=res.data.data
			}
		})
		},
		
		go_page(id,name) {
			this.$router.push({
				name: 'single',
				query:{
					id:id
				}
			})
		},
		go_page2(id,name) {
			this.$router.push({
				name: 'about',
				query:{
					id:id
				}
			})
		},
	}

}
</script>

<style lang='scss' scoped>
.home {
	width: 100%;
	background: #171719;

	.home_div {
		width: 1230px;
		margin: 0 auto;
		padding: 30px 0;
		display: flex;
		justify-content: space-between;
		box-sizing: border-box;
		.foot_left {
			img {
				width: 275px;
				height: 74px;
				display: block;
			}
			.foot_left_div {
				width: 376px;
				margin-top: 30px;
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				div {
					width: 33%;
					display: flex;
					justify-content: space-around;
					font-size: 16px;
					color: #8d8d8d;
					margin: 7px 0;
					font-weight: 600;
					cursor: pointer;
				}
			}
		}
		.foot_center {
			margin-right: 100px;
			p {
				font-size: 16px;
				color: #ffffff;
				font-weight: 400;
				text-align: left;
				margin: 10px 0;
			}
			.foot_center_t {
				img {
					width: 127px;
					height: 44px;
					display: inline-block;
					margin: 0 5px;
				}
			}
			.foot_center_b {
				div {
					display: flex;
					justify-content: space-between;
					img {
						width: 22px;
						height: auto;
						cursor: pointer;
					}
				}
			}
		}
		.foot_right {
			img {
				width: 145px;
				height: 46px;
				display: block;
				margin: 10px 0;
			}
		}
	}
	.foot_b {
		background: #0a0a0c;
		color: #8d8d8d;
		padding: 20px 0;
		text-align: center;
		p{
			display: flex;
			align-items: center;
			justify-content: center;
			img{
				margin: 0 10px;
			}
		}
	}
}
</style>